



























import { defineComponent } from '@nuxtjs/composition-api';
import { useUiNotification } from '~/composables';

export default defineComponent({
  name: 'NotificationBar',
  setup() {
    const { notifications } = useUiNotification();

    return {
      notifications,
    };
  },
});
