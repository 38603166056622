//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'RkNotification',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String | Error,
      default: '',
    },
    message: {
      type: String | Error,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'secondary',
      validator: function (value) {
        return ['secondary', 'info', 'success', 'warning', 'danger', 'error'].includes(value);
      },
    },
  },
  setup(props, { emit }) {
    const notificationIcon = computed(() => {
      switch (props.type) {
        case 'success':
          return 'check-circle';
        case 'info':
          return 'info';
        case 'warning':
          return 'exclamation-circle';
        case 'danger':
          return 'cross-circle';
        case 'error':
          return 'cross-circle';
        default:
          return 'info';
      }
    });

    const colorClass = computed(() => {
      switch (props.type) {
        case 'secondary':
          return 'color-secondary';
        case 'info':
          return 'color-info';
        case 'success':
          return 'color-success';
        case 'warning':
          return 'color-warning';
        case 'danger':
          return 'color-danger';
        case 'error':
          return 'color-danger';
        default:
          return 'color-info';
      }
    });

    const actionHandler = () => {
      /**
       * Event for action button
       * @type {Event}
       */
      emit('click:action');
    };

    const closeHandler = () => {
      /**
       * Event for close icon
       * @type {Event}
       */
      emit('click:close');
    };

    return {
      notificationIcon,
      colorClass,
      actionHandler,
      closeHandler,
    };
  },
});
